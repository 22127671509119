import { createApp } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import App from './App.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
// import {  } from '@fortawesome/free-solid-svg-icons'
import { faFacebookMessenger, faWhatsapp, faSignalMessenger, faTelegram } from '@fortawesome/free-brands-svg-icons'

library.add(faTelegram, faFacebookMessenger, faWhatsapp, faSignalMessenger)

const app = createApp(App)

app.use(createRouter({
  history: createWebHashHistory(),
  routes: [
    { path: '/', component: () => import('./screens/HomeScreen.vue') },
    { path: '/ventures', component: () => import('./screens/VenturesScreen.vue') },
    { path: '/ventures/:slug', component: () => import('./screens/VentureScreen.vue') },
  ]
}))

axios.defaults.baseURL = 'https://become.liberstarter.com/wp-json/wp/v2'
app.use(VueAxios, axios)
app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app')