<template>
  <router-view></router-view>
</template>

<script>
export default {
}
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,500;1,100;1,500&display=swap');

body, html {
  padding: 0;
  margin: 0;
}

body {
  background: black;
  font-family: 'Roboto';
  font-size: 12spx;
}

.full-height-min {
  min-height: 100vh;
}

p {
  box-sizing: border-box;
}

q {
  font-weight: 100;
  font-style: italic;
  text-align: justify;
}

.card {
  background-color: black;
  padding: 10px;
  border-radius: 10px;
  box-sizing: border-box;
}

.button, .button:hover, .button:visited, .button:link, .button:active {
  display: inline-block;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  box-sizing: border-box;
  background: white;
  color: black;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;
  margin: 5px;
  font-weight: 500;
}

.button:hover, .button:active {
  background: #ffcc00;
}

.heading {
  padding: 0;
  margin: 0;
  margin-bottom: 5px;
}

h3 {
  font-size: 23px;
  text-transform: uppercase;
}

h1 {
  font-size: 30px;
  text-transform: uppercase;
}

.content {
  padding: 60px 20% 60px 20%;
}

@media screen and (max-width: 700px) {
  .content {
    padding: 60px 20px 60px 20px;
  }
}

/* scrollbar */

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #ffcc00;
  border: 0px none #ffcc00;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #ffcc00;
}
::-webkit-scrollbar-thumb:active {
  background: #000000;
}
::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #666666;
}
::-webkit-scrollbar-track:active {
  background: #333333;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

</style>
